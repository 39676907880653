<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
              <e-label>Tipo de aula</e-label>
              <e-autocomplete
                :items="$constants.tiposDeAulas"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.tipo_de_aula"
                @change="() => verificaTipoDaAula()"
                label="Selecione um tipo de aula"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row v-if="mostrarCampos">
          <v-col class="pt-0 pb-0" cols="4">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <v-text-field
                  v-model="aulaSelecionada.data"
                  persistent-hint
                  :disabled="aulaSelecionada.tipo_de_aula ? false : true"
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.dialog.save(
                      (data = data
                        ? data
                        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .substr(0, 10))
                    )
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
              <e-label>Selecione um horário</e-label>
              <e-autocomplete
                :disabled="aulaSelecionada.data ? false : true"
                :items="horarios"
                :error-messages="errors"
                label="Selecione uma opção"
                :item-text="(item) => item.descricao"
                v-model="horarioSelecionado"
                return-object
                dense
              />
            </ValidationProvider>
            <!-- @change="() => pesquisaAulas()" -->
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dia da semana</e-label>
            <v-text-field solo dense v-model="aulaSelecionada.dia_da_semana" :disabled="true">
            </v-text-field>
          </v-col>

          <v-col cols="12"> </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <h4>Campos de experiencias</h4>
            <v-row class="">
              <v-col class="pt-0 pb-0" cols="4" v-for="(el, index) of opcoes" :key="index">
                <v-checkbox
                  v-model="campos_de_experiencias[index]"
                  :label="el"
                  :value="el"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-if="horarioSelecionado || aulaSelecionada.tipo_de_aula === 'Aula Remota' || editing"
          >
            <ValidationProvider name="conteudo" rules="required" v-slot="{ errors }">
              <e-label>Eixos temáticos</e-label>
              <v-textarea solo dense v-model="aulaSelecionada.eixos" :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider name="estrategias" rules="required" v-slot="{ errors }">
              <e-label>Estratégias de ensino </e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.estrategias"
                :error-messages="errors"
              />
            </ValidationProvider>

            <ValidationProvider name="recursos" rules="required" v-slot="{ errors }">
              <e-label>Recursos</e-label>
              <v-textarea solo dense v-model="aulaSelecionada.recursos" :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider name="atividade_classe" rules="required" v-slot="{ errors }">
              <e-label>Atividade de classe</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.atividade_classe"
                :error-messages="errors"
              />
            </ValidationProvider>

            <ValidationProvider name="atividade_casa" rules="required" v-slot="{ errors }">
              <e-label>Atividade de casa</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.atividade_casa"
                :error-messages="errors"
              />
            </ValidationProvider>

            <e-label>Observações</e-label>
            <v-textarea solo dense v-model="aulaSelecionada.observacoes" />

            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.duplicar = true"
              >Duplicar Aula</v-btn
            >
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.exportar = true"
              >Exportar Aula</v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="!aulaSelecionada.tipo_de_aula || !aulaSelecionada.data || !horarioSelecionado">
          <v-col cols="12" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'">
            <v-alert type="warning" border="left">
              Por favor, preencha os campos obrigatórios!
            </v-alert>
          </v-col>
        </v-row>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao ? item.situacao : "Aguardando" }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
      :horarios="horarios"
    >
    </e-modal-exportar-aula>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      opcoes: [
        "O eu, o outro e o nós",
        "Corpo, gestos e movimentos",
        "Escuta, fala, pensamento e imaginação",
        "Traços, sons, cores e formas",
        "Espaço, tempo, quantidades, relações e transformações",
      ],
      data: null,
      modal: false,
      menu: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      diasSemana: this.$constants.diasDaSemana,
      mostrarCampos: true,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      campos_de_experiencias: [],
      aulaSelecionada: {
        data: null,
        dia_da_semana: "",
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
    };
  },
  watch: {
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = null;
      }
    },
    data(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
      this.diaDaSemana();
    },
  },
  methods: {
    async pesquisaAulas() {
      const data_aula = this.aulaSelecionada.data;
      /* eslint-disable */
      const aula_id = this.$route.params.aula_id;
      const horario_id = this.horarioSelecionado.id;
      const turma_id = this.gestaoDeAula.turma.id;
      if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada) return;

      const response = await this.$services.aulasService.pesquisarAulasEmConflitos(
        data_aula,
        horario_id,
        turma_id,
        aula_id
      );

      if (response.data.length <= 0) {
        this.mostrarCampos = true;
        this.outrosCampos = true;
      }

      if (response.data.length > 0) {
        this.aulasEmConflitos = true;
        this.outrosCampos = false;
        this.aulasConflitadas = response.data;
      }
    },
    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        this.getAula();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async submitForm() {
      this.aulaSelecionada.e_aula_infantil = 1;
      this.aulaSelecionada.campos_de_experiencias = this.campos_de_experiencias;
      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;

      this.submittingForm = true;
      try {
        if (this.editing) {
          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(this.$route.params.gestaoDeAulaId, 10);
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          await this.$services.aulasService.atualizar(this.aulaSelecionada);
          this.$toast.success("Aula editada com sucesso!");
        } else {
          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(this.$route.params.gestaoDeAulaId, 10);
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
          this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          await this.$services.aulasService.criarAula(this.aulaSelecionada);
          this.$toast.success("Aula cadastrada com sucesso!");
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
      this.$router.push({
        name: "aulas",
      });
    },
    async getAula() {
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;
      this.horarios = response.payload.horarios;
      // this.horarios = await this.$services.horariosService.horariosAll();
      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));
      this.horarioSelecionado = aulaSelecionada.horario ?? null;
      this.campos_de_experiencias = aulaSelecionada.campos_de_experiencias
        ? aulaSelecionada.campos_de_experiencias
        : [];
      return aulaSelecionada;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getAula();
        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    diaDaSemana() {
      var data_do_usuario = this.aulaSelecionada.data;
      var data_do_usuario_formatada = data_do_usuario.replace(/\//g, "-");
      var array1 = data_do_usuario_formatada.split("-");
      var array2 = array1.reverse();
      var data_final = new Date(array2[0], parseInt(array2[1], 10) - 1, array2[2]);
      var dia = this.diasSemana[data_final.getDay()];
      this.aulaSelecionada.dia_da_semana = dia;
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
