<template>
  <div class="wrapper">
    <v-card elevation="2" class="login-card">
      <v-card-title class="d-flex justify-center">
        <img src="/img/logo-notifiq.png" width="100" />
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="logar">
          <v-text-field
            v-if="input_view"
            v-model="rfid"
            filled
            color="success_icon"
            prepend-inner-icon="mdi-account-outline"
            label="Código RFID"
            required
            ref="input"
          />
          <v-btn v-if="false" type="submit"> </v-btn>
        </v-form>
      </v-card-text>
      <div>
        <v-col cols="12">
          <v-alert
            v-if="dispathMesseger"
            style="font-size: 1.7rem"
            dense
            text
            :color="notifiq.tipo"
            type="success"
          >
            {{ notifiq.mensagem }}
          </v-alert>
          <v-col v-if="presenca">
            <v-row class="text-center d-flex justify-center">
              <img
                :src="url_image_aluno"
                width="300"
                class="secondary rounded-circle d-inline-block"
              />
            </v-row>
            <v-col cols="12">
              <h3>Nome:</h3>
              <br />
              <h3>{{ matricula.aluno ? matricula.aluno.nomecompleto : "- - -" }}</h3>
            </v-col>
            <v-col cols="12">
              <h3>Turma:</h3>
              <br />
              <h3>{{ matricula.turma ? matricula.turma.descricao : "- - -" }}</h3>
            </v-col>

            <v-col cols="12">
              <h3>Responsavel:</h3>
              <br />
              <h3>
                {{ matricula.aluno.responsavel ? matricula.aluno.responsavel.nome : "- - -" }}
              </h3>
            </v-col>
          </v-col>
        </v-col>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "NotifiqPage",
  data() {
    return {
      rfid: "",
      presenca: false,
      notifiq: {
        tipo: "green",
        mensagem: "teste",
      },
      dispathMesseger: false,
      matricula: null,
      url_image_aluno: "",
      input_view: true,
    };
  },
  mounted() {
    this.$refs.input.focus();
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    // this.$Tawk.$hideWidget();
  },
  methods: {
    async loadImage(aluno_id) {
      this.url_image_aluno = await this.$services.imageService.downloadImage(
        `download/aluno/image/${aluno_id}`
      );
    },
    async logar() {
      this.logando = true;
      this.input_view = false;
      try {
        if (!this.rfid || this.rfid === "") {
          this.notifiq = {
            tipo: "error",
            mensagem: "Informe um código",
          };
          this.dispathMesseger = !this.dispathMesseger;
          setTimeout(() => {
            this.dispathMesseger = !this.dispathMesseger;
            this.url_image_aluno = "";
            this.rfid = "";
            this.$refs.input.focus();
          }, 2000);
          this.input_view = true;
          this.logando = false;
          return;
        }
        const response = await this.$services.notifiqService.rfid(this.rfid);
        this.notifiq = response.mensagem;
        // console.log("response", response);
        // console.log("notifiq", this.notifiq);
        // console.log("response.dados", response.dados);
        this.dispathMesseger = !this.dispathMesseger;
        if (response.dados) {
          this.matricula = response.dados;
          this.presenca = true;
          await this.loadImage(response.dados.aluno_id);
        }

        setTimeout(() => {
          this.dispathMesseger = !this.dispathMesseger;
          this.presenca = false;
          this.url_image_aluno = "";
          this.rfid = "";
          this.$nextTick(() => {
            this.$refs.input.focus();
          });
        }, 2000);
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.input_view = true;
        this.logando = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("../../assets/background.png");
  background-size: cover;
}
.wrapper > .login-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
}

.help-section > i {
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 50%;
  margin-right: 50%;
  transform: translateX(-50%);
}

.help-section {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}
</style>
